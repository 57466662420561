import React from "react";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";

function PaymentGateway() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center gap-y-6 bg-gray-100 ">
      {/* Alert Banner */}
      <p className="w-full max-w-3xl rounded-xl bg-yellow-300 p-4 text-center font-medium text-gray-800 shadow-lg border border-yellow-500">
        <span className="font-semibold">Alert:</span> Kindly maintain your minimum balance of ₹ 50000 or get in touch with our support team.
      </p>

      {/* Contact Box */}
      <div className="w-full max-w-4xl rounded-2xl bg-white p-8 text-center shadow-lg border-t-8 border-blue-600">
        <h1 className="mb-4 text-4xl font-semibold text-blue-700">Get in Touch</h1>
        <p className="mb-8 text-base text-gray-600">
          We're here to assist you. Feel free to contact us anytime!
        </p>

        {/* Contact Methods */}
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          {/* Call Support */}
          <div className="rounded-lg bg-gray-50 p-6 shadow-sm hover:shadow-lg transition-shadow duration-300">
            <AiOutlinePhone className="mx-auto mb-3 text-5xl text-blue-500" />
            <h2 className="mb-2 text-xl font-semibold text-gray-800">Call Us</h2>
            <p className="mb-2 text-gray-600">+91 7209058182</p>
            <a href="tel:+917209058182">
              <button className="rounded-lg bg-blue-500 px-5 py-2 text-white hover:bg-blue-600 transition-colors">
                Call Now
              </button>
            </a>
          </div>

          {/* Email Support */}
          {/* <div className="rounded-lg bg-gray-50 p-6 shadow-sm hover:shadow-lg transition-shadow duration-300">
            <AiOutlineMail className="mx-auto mb-3 text-5xl text-blue-500" />
            <h2 className="mb-2 text-xl font-semibold text-gray-800">Email Us</h2>
            <p className="mb-2 text-gray-600">support@virtualbank.com</p>
            <a href="mailto:support@virtualbank.com">
              <button className="rounded-lg bg-blue-500 px-5 py-2 text-white hover:bg-blue-600 transition-colors">
                Email Now
              </button>
            </a>
          </div> */}

          {/* WhatsApp Support */}
          <div className="rounded-lg bg-gray-50 p-6 shadow-sm hover:shadow-lg transition-shadow duration-300">
            <FaWhatsapp className="mx-auto mb-3 text-5xl text-green-500" />
            <h2 className="mb-2 text-xl font-semibold text-gray-800">
              WhatsApp Us
            </h2>
            <p className="mb-2 text-gray-600">+91 72090 58182</p>
            <a
              href="https://wa.me/917209058182"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="rounded-lg bg-green-500 px-5 py-2 text-white hover:bg-green-600 transition-colors">
                WhatsApp Now
              </button>
            </a>
          </div>
        </div>

        {/* Additional Info */}
        <div className="mt-10 text-gray-500">
          <p>For more details, feel free to reach out. We're happy to help you with all your banking needs.</p>
        </div>
      </div>
    </div>
  );
}

export default PaymentGateway;
