import React from "react";
 
import UserList from "../cards/UserList";

function HomeAdmin({ isAdminView = false }) {
  return (
    <div>
      
      {isAdminView && <UserList />}
    </div>
  );
}

export default HomeAdmin;
