import React from "react";
import { FiPhoneCall } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";

const FloatingButtons = ({ isOpen = true, setIsOpen }) => {
  return (
    <div className="fixed w-full z-[2] bottom-4 right-4 flex flex-row items-center ">
      {/* Apply Now GIF */}
      <div className="flex-1 ml-4">
        <img
          onClick={() => setIsOpen(!isOpen)}
          src="https://www.technofino.in/wp-content/uploads/2022/11/giphy.gif"
          alt="apply now gif"
          className="h-16 w-auto" // Adjust the height and width as per your design
        />
      </div>

      {/* Buttons */}
      <div className="flex flex-col gap-y-4">
        {/* Call Button */}
        <a
          href="tel:+917209058182" // Replace with your phone number
          className="bg-blue-500 text-white p-4 rounded-full shadow-lg flex items-center justify-center hover:bg-blue-600 transition duration-300"
          aria-label="Call us"
        >
          <FiPhoneCall className="text-4xl" />
        </a>

        {/* WhatsApp Button */}
        <a
          href="https://wa.me/+917209058182" // Replace with your WhatsApp number
          className="bg-green-500 text-white p-4 rounded-full shadow-lg flex items-center justify-center hover:bg-green-600 transition duration-300"
          aria-label="WhatsApp us"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp className="text-4xl" />
        </a>
      </div>
    </div>
  );
};

export default FloatingButtons;
