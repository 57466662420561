import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import Disclaimer from "./Disclaimer";
import { Link } from "react-router-dom"; // For routing to other pages
import "./Footer.css"; // Import the CSS file for styling
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
export const Contact = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    workType: "",
    hasLaptop: "",
    deviceId: "",
    hasMobile: "",
  });
  const getDeviceFingerprint = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const deviceId = result.visitorId;
    return deviceId;
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.phone || !/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    if (!formData.workType) {
      newErrors.workType = "Work type is required";
    }
    if (!formData.hasLaptop) {
      newErrors.hasLaptop = "Please select if you have a laptop";
    }
    if (!formData.hasMobile) {
      newErrors.hasMobile = "Please select if you have a mobile";
    }
    if (!formData.deviceId) {
      newErrors.email = "This Device can not use for this application";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const deviceId = await getDeviceFingerprint();
    formData.deviceId = deviceId;

    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post("/api/formsubmit", formData);
      if (response.status === 200) {
        toast.success("Form submitted successfully!");
        toast.success(response.data.message);
        localStorage.setItem("userToken", response?.data?.token);
         
        setTimeout(() => {
          window.location.href = "/user-dashboard";
        }, 2000);
      } else {
        toast.error(response?.data?.message || "Error During Form Submit");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Error During Form Submit");
    }
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-6">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>Start Earning Today!</p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email Address"
                        required
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="phone"
                        className="form-control"
                        placeholder="Phone Number"
                        required
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label style={{ color: "white" }}>Type of Work</label>
                      <select
                        name="workType"
                        className="form-control"
                        value={formData.workType}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Select Work Type
                        </option>
                        <option value="full-time">Full-time</option>
                        <option value="part-time">Part-time</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label style={{ color: "white" }}>
                        Do you have a Laptop?
                      </label>
                      <div className="radio-group">
                        <label style={{ color: "white" }}>
                          <input
                            type="radio"
                            name="hasLaptop"
                            value="yes"
                            checked={formData.hasLaptop === "yes"}
                            onChange={handleChange}
                          />{" "}
                          Yes
                        </label>
                        <label style={{ color: "white" }}>
                          <input
                            type="radio"
                            name="hasLaptop"
                            value="no"
                            checked={formData.hasLaptop === "no"}
                            onChange={handleChange}
                          />{" "}
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label style={{ color: "white" }}>
                        Do you have a Mobile?
                      </label>
                      <div className="radio-group">
                        <label style={{ color: "white" }}>
                          <input
                            type="radio"
                            name="hasMobile"
                            value="yes"
                            checked={formData.hasMobile === "yes"}
                            onChange={handleChange}
                          />{" "}
                          Yes
                        </label>
                        <label style={{ color: "white" }}>
                          <input
                            type="radio"
                            name="hasMobile"
                            value="no"
                            checked={formData.hasMobile === "no"}
                            onChange={handleChange}
                          />{" "}
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>

          {/* Google Map iframe */}
          <div
            className="col-md-6  "
            style={{ position: "relative", overflow: "hidden" }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7001.706518109408!2d77.13197827605929!3d28.694306799665404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0292d7a145e1%3A0x8c8192c72a848702!2sM5R2%2B77P%20Pitampura%2C%20Netaji%20Subhash%20Place%2C%20Shakurpur%2C%20Delhi%2C%20110034!5e0!3m2!1sen!2sin!4v1697459999999!5m2!1sen!2sin"
              width="100%"
              height="77%"
              frameBorder="0"
              style={{
                border: 0,
                marginTop: "20px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
              }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>

          {/* Social media icons (commented out in case you want to use them later) */}
        </div>
      </div>
      <Disclaimer />
      <footer className="vivaan-footer">
        <div className="vivaan-footer-container">
          {/* Social Media Section */}
          <div className="vivaan-footer-social">
            <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
              <i className="fa fa-facebook"></i>
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noreferrer">
              <i className="fa fa-twitter"></i>
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
              <i className="fa fa-linkedin"></i>
            </a>
          </div>

          {/* Links Section */}
          <div className="vivaan-footer-links">
            <Link to="/privacy" className="footer-link">
              Privacy Policy
            </Link>
          </div>

          {/* Contact Info Section */}
          <div className="vivaan-footer-contact">
            <p>
              <i className="fa fa-envelope"></i> Email:
              info@vihanenterprises.online
            </p>
            <p>
              <i className="fa fa-phone"></i> Phone: +91 720 9058182
            </p>
            <p>
              <i className="fa fa-map-marker"></i>302, 3rd Floor, NDM2, Netaji
              Subhash Place, Pitam Pura, New Delhi-110034
            </p>
          </div>

          {/* Copyright Section */}
          <p className="vivaan-footer-copyright">
            &copy; 2024 vihan enterprises . All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};
