import React from "react";
import "./PlansComparison.css"; // Import your CSS file for styling
import {
  FaStar,
  FaCrown,
  FaGem,
  FaMedal,
  FaRocket,
  FaAward,
} from "react-icons/fa";
const PlansComparison = ({
  isOpen,
  isShowPlaneA = true,
  showFromAdmin = false,
  setIsOpen,
}) => {
  const plans = [
    {
      name: "FREE",
      price: 0,
      tasks: 25,
      profitPerTask: 1,
      totalProfit: 25,
      icon: FaStar,
    },
    {
      name: "Basic",
      price: 149,
      tasks: 149,
      profitPerTask: 1.5,
      totalProfit: 224,
      icon: FaStar,
    },
    {
      name: "Silver",
      price: 199,
      tasks: 199,
      profitPerTask: 1.5,
      totalProfit: 298,
      icon: FaMedal,
    },
    {
      name: "Gold",
      price: 239,
      tasks: 239,
      profitPerTask: 1.5,
      totalProfit: 239 * 1.5,
      icon: FaCrown,
    },
    {
      name: "Platinum",
      price: 499,
      tasks: 499,
      profitPerTask: 1.5,
      totalProfit: 499 * 1.5,
      icon: FaGem,
    },
    {
      name: "Diamond",
      price: 999,
      tasks: 1000,
      profitPerTask: 1.5,
      totalProfit: 999 * 1.5,
      icon: FaRocket,
    },
    {
      name: "Elite",
      price: 1999,
      tasks: 2000,
      profitPerTask: 1.5,
      totalProfit: 2000 * 1.5,
      icon: FaAward,
    },
  ];

  return (
    <div id="plan" className="plans-comparison">
      <h2 className="plans-title">Choose the Best Plan for You</h2>
      <div className="plans-wrapper">
        {plans.map((plan, index) =>
          !isShowPlaneA && index === 0 ? null : (
            <div className="plan-card" key={index}>
              <h3 className="plan-name">{plan.name}</h3>
              <div className="plan-price">{plan.price}₹</div>
              <ul className="plan-details">
                <li>
                  <strong>{plan.tasks}</strong> tasks
                </li>
                <li>
                  ₹<strong>{plan.profitPerTask}</strong> per task
                </li>
                <li>
                  Total Profit: ₹<strong>{plan.totalProfit}</strong>
                </li>
              </ul>
              {!showFromAdmin && (
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="select-plan-btn"
                >
                  Select Plan
                </button>
              )}
              {showFromAdmin && (
                <a
                  className="select-plan-btn"
                  href={`https://wa.me/917209058182?text=Hey,%20I%20want%20to%20upgrade%20to%20${plan.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pay Now
                </a>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default PlansComparison;
