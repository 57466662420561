import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const PlansPopup = ({ isOpen, setIsOpen }) => {
  const plans = [
   
    {
      name: "Plan B",
      price: "₹2500",
      tasks: 100,
      ratePerTask: 50,
      totalProfit: 5000,
    },
    {
      name: "Plan C",
      price: "₹5000",
      tasks: 150,
      ratePerTask: 100,
      totalProfit: 15000,
    },
    {
      name: "Plan D",
      price: "₹10,000",
      tasks: 200,
      ratePerTask: 150,
      totalProfit: 30000,
    },
  ];

  const handleSelectPlan = (planName) => {
    const whatsappLink = `https://wa.me/917209058182?text=Hey,%20I%20want%20to%20upgrade%20to%20${planName}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      className="fixed inset-0 flex items-center justify-center p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg shadow-lg w-full max-w-4xl mx-auto p-6 space-y-6 relative">
        {/* Close Button */}
        <button
          onClick={() => setIsOpen(false)}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
        >
          &times;
        </button>

        <h2 className="text-2xl font-bold text-center text-gray-800">
          Choose the Best Plan for You
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {plans.map((plan, index) => (
            <div
              key={index}
              className="bg-gradient-to-r from-green-400 to-green-500 p-6 rounded-lg shadow-md transform transition hover:scale-105"
            >
              <h3 className="text-xl font-semibold text-white mb-4">
                {plan.name}
              </h3>
              <div className="text-2xl font-bold text-white mb-4">
                {plan.price}
              </div>
              <ul className="text-white mb-4 space-y-2">
                <li>
                  <strong>{plan.tasks}</strong> tasks
                </li>
                <li>
                  ₹<strong>{plan.ratePerTask}</strong> per task
                </li>
                <li>
                  Total Profit: ₹<strong>{plan.totalProfit}</strong>
                </li>
              </ul>
              <button
                onClick={() => handleSelectPlan(plan.name)}
                className="w-full bg-white text-green-600 hover:bg-green-600 hover:text-white font-semibold py-2 px-4 rounded-lg transition"
              >
                Select {plan.name}
              </button>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default PlansPopup;
