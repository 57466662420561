import React, { useState, useEffect } from "react";
import { FaMoneyBillWave } from "react-icons/fa"; // Money icon from react-icons
import "./RandomPopup.css"; // For additional CSS styles

// 200 Real Names
const randomNames = [
  "Ravi",
  "Neha",
  "Amit",
  "Priya",
  "Anjali",
  "Vivek",
  "Karan",
  "Sonia",
  "Rahul",
  "Meera",
  "Aakash",
  "Sneha",
  "Deepak",
  "Aarav",
  "Simran",
  "Raj",
  "Shweta",
  "Vikas",
  "Harsh",
  "Kavya",
  "Manish",
  "Naina",
  "Rohan",
  "Pooja",
  "Akshay",
  "Riya",
  "Abhay",
  "Sanjay",
  "Shreya",
  "Aryan",
  "Vikram",
  "Tarun",
  "Ankit",
  "Sakshi",
  "Prateek",
  "Rakesh",
  "Tanya",
  "Kritika",
  "Sahil",
  "Geeta",
  "Vijay",
  "Manoj",
  "Mohit",
  "Swati",
  "Nikhil",
  "Rajesh",
  "Lata",
  "Ashok",
  "Preeti",
  "Kunal",
  "Ajay",
  "Sheetal",
  "Parth",
  "Siddharth",
  "Anurag",
  "Garima",
  "Gaurav",
  "Hina",
  "Dhruv",
  "Isha",
  "Irfan",
  "Jyoti",
  "Karan",
  "Lakshmi",
  "Mohini",
  "Nishant",
  "Omkar",
  "Pankaj",
  "Qasim",
  "Richa",
  "Sahil",
  "Tushar",
  "Usha",
  "Varun",
  "Yash",
  "Zoya",
  "Kabir",
  "Maya",
  "Naveen",
  "Rashi",
  "Veena",
  "Nidhi",
  "Yuvraj",
  "Kartik",
  "Shivam",
  "Ayesha",
  "Bhavya",
  "Aastha",
  "Dev",
  "Gargi",
  "Hemant",
  "Iqbal",
  "Jeet",
  "Karishma",
  "Leena",
  "Madhur",
  "Nina",
  "Om",
  "Poonam",
  "Rana",
  "Shalini",
  "Tejas",
  "Uma",
  "Vani",
  "Zain",
  "Aarushi",
  "Bhanu",
  "Chirag",
  "Diya",
  "Ekta",
  "Faisal",
  "Gulshan",
  "Hitesh",
  "Ira",
  "Jatin",
  "Kushal",
  "Lalit",
  "Mehul",
  "Namita",
  "Omar",
  "Palak",
  "Quazi",
  "Ragini",
  "Sandeep",
  "Tanmay",
  "Udit",
  "Vidya",
  "Zara",
  "Anvi",
  "Bharti",
  "Chaitanya",
  "Devika",
  "Esha",
  "Falguni",
  "Gyan",
  "Harpreet",
  "Ishaan",
  "Jai",
  "Khushboo",
  "Lavanya",
  "Mahesh",
  "Neeraj",
  "Ojas",
  "Prerna",
  "Ravina",
  "Sushant",
  "Trisha",
  "Urvashi",
  "Vikash",
  "Yamini",
  "Zahir",
  "Avni",
  "Brijesh",
  "Chhavi",
  "Deepika",
  "Eshaan",
  "Farah",
  "Gul",
  "Harman",
  "Inder",
  "Jaya",
  "Kashish",
  "Lavi",
  "Meera",
  "Nishtha",
  "Omi",
  "Pranav",
  "Rutuja",
  "Suman",
  "Tanvi",
  "Vaibhav",
  "Yashika",
  "Zubair",
  "Aditi",
  "Brinda",
  "Charu",
  "Dinesh",
  "Eklavya",
  "Fahad",
  "Gagan",
  "Heena",
  "Ipsita",
  "Jagruti",
  "Kaushal",
  "Laksh",
  "Mitali",
  "Neha",
  "Oshin",
  "Pratiksha",
  "Rahul",
  "Sanjana",
];

// 200 Random Earnings/Amounts
const randomEarnings = Array.from(
  { length: 200 },
  () => Math.floor(Math.random() * 10000) + 100
);

// Random actions like received, withdrawn, added, spent, donated, etc.
const randomActions = [
  "received",
  "withdrawn",
  "added",
  "spent",
  "transferred",
  "invested",
];

const notificationSoundUrl =
  "https://www.soundjay.com/buttons/sounds/button-37a.mp3";

const RandomPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({
    name: "",
    earning: 0,
    action: "",
  });

  // Function to trigger the popup
  const triggerRandomPopup = () => {
    const randomName =
      randomNames[Math.floor(Math.random() * randomNames.length)];
    const randomEarning =
      randomEarnings[Math.floor(Math.random() * randomEarnings.length)];
    const randomAction =
      randomActions[Math.floor(Math.random() * randomActions.length)];

    setPopupContent({
      name: randomName,
      earning: randomEarning,
      action: randomAction,
    });
    setShowPopup(true);

    // Play the notification sound when the popup appears
    const audio = new Audio(notificationSoundUrl);
    audio.play().catch((error) => console.log("Audio playback error:", error));

    // Automatically close popup after 2-3 seconds
    setTimeout(() => {
      setShowPopup(false);
    }, Math.random() * 4000 + 4000); // Popup duration between 2-3 seconds
  };

  // Use useEffect to trigger the popup at random intervals
  useEffect(() => {
    const interval = setInterval(() => {
      triggerRandomPopup();
    }, Math.random() * 8000 + 15000); // Random interval between 3 and 8 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div>
      {/* Random Popup */}
      {showPopup && (
        <div className="popup-card fixed top-4 z-[9999999] right-4 p-4 rounded-lg shadow-lg bg-gradient-to-r from-purple-500 to-indigo-600 text-white flex items-center gap-3 transform translate-x-full fade-in animated-popup">
          <FaMoneyBillWave className="text-4xl text-yellow-300 animate-bounce" />
          <div className="flex items-center gap-2">
            <h3 className="text-xl font-bold">
              {popupContent.name} {popupContent.action}
            </h3>
            <p className="text-3xl font-semibold">₹{popupContent.earning}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default RandomPopup;
