import React, { useEffect, useState } from "react";
import {
  FaStar,
  FaCrown,
  FaGem,
  FaMedal,
  FaRocket,
  FaAward,
} from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify"; // For toast notifications
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { QRCodeCanvas } from "qrcode.react"; // Updated import
import { i } from "framer-motion/client";

const plans = [
  {
    name: "Basic",
    price: 149,
    tasks: 149,
    profitPerTask: 1.5,
    totalProfit: 224,
    icon: FaStar,
  },
  {
    name: "Silver",
    price: 199,
    tasks: 199,
    profitPerTask: 1.5,
    totalProfit: 298,
    icon: FaMedal,
  },
  {
    name: "Gold",
    price: 239,
    tasks: 239,
    profitPerTask: 1.5,
    totalProfit: 239 * 1.5,
    icon: FaCrown,
  },
  {
    name: "Platinum",
    price: 499,
    tasks: 499,
    profitPerTask: 1.5,
    totalProfit: 499 * 1.5,
    icon: FaGem,
  },
  {
    name: "Diamond",
    price: 999,
    tasks: 1000,
    profitPerTask: 1.5,
    totalProfit: 999 * 1.5,
    icon: FaRocket,
  },
  {
    name: "Elite",
    price: 1999,
    tasks: 2000,
    profitPerTask: 1.5,
    totalProfit: 2000 * 1.5,
    icon: FaAward,
  },
];
const UpgradeNow = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [transactionId, setTransactionId] = useState("");

  // Mocked purchased plans data
  const [purchasedPlans, setPurchasedPlans] = useState(null);

  const getAllPlans = async () => {
    try {
      const res = await axios.post("/api/allPurchasedPlans", {
        userToken: localStorage.getItem("userToken"),
      });

      if (res.status === 200) {
        setPurchasedPlans(res.data.plans);
      } else {
        setPurchasedPlans([]);
      }
    } catch (error) {
      setPurchasedPlans([]);
      toast.error(
        error.message || "We are unable to fetch perivious purchased Plans"
      );
    }
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  const handleUpgradeClick = (plan) => {
    setSelectedPlan(plan);
    setShowModal(true);
  };

  const handleSubmitTransaction = async () => {
    try {
      const res = await axios.post("/api/submit-transaction", {
        selectedPlan: selectedPlan,
        transactionId,
        userToken: localStorage.getItem("userToken"),
      });

      if (res.status === 200) {
        toast.success(res.data.message);
        setShowModal(false); // Close the modal
        setTransactionId(""); // Clear the input field
        getAllPlans();
      } else {
        toast.error(
          res?.data?.message ||
            "Transaction submission failed! Please try again."
        );
      }
    } catch (error) {
      toast.error("Transaction submission failed! Please try again.");
    }
  };

  const [upi, setUpi] = useState(null);

  useEffect(() => {
    getUPI();
  }, []);
  const getUPI = async () => {
    try {
      const response = await axios.post("/api/getUPI", {
        userToken: localStorage.getItem("userToken"),
      });
      if (response.status === 200) {
        setUpi(response.data.upi);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error submitting ticket", error);
    }
  };

  return (
    <div className="w-full bg-amber-300 flex flex-col items-center justify-center p-4">
      <div className="flex flex-wrap justify-center gap-6">
        {plans.map((plan, index) => {
          const isAlreadyPurchased = purchasedPlans?.some(
            (plans) => plans.name === plan.name && plans.status === "approved"
          );

          const Icon = plan.icon;
          return (
            purchasedPlans && (
              <div
                key={index}
                className={
                  isAlreadyPurchased
                    ? "w-[150px] shadow-green-400 h-[150px] opacity-30 bg-red-400   border rounded-xl shadow-md flex flex-col items-center justify-between p-6 hover:shadow-lg transition-shadow duration-300"
                    : "w-[150px] shadow-green-400  h-[150px] bg-gray-400/90 hover:bg-yellow-200 border rounded-xl shadow-md flex flex-col items-center justify-between p-6 hover:shadow-lg transition-shadow duration-300"
                }
              >
                {/* Icon */}
                <div className="text-4xl text-blue-500">
                  <Icon />
                </div>

                {/* Plan Details */}
                <div className="text-center">
                  <h2 className="text-xl font-bold text-gray-800">
                    {plan.name}
                  </h2>
                  <p className="text-[12px]  text-gray-600 mt-2">
                    <strong>Price:</strong> ₹{plan.price}
                  </p>
                  <p className="text-sm text-gray-600">
                    <strong>Tasks:</strong> {plan.tasks}
                  </p>
                  <p className="text-sm text-gray-600">
                    <strong>Profit Per Task:</strong> {plan.profitPerTask}
                  </p>
                  <p className="text-sm text-gray-600">
                    <strong>Total Profit:</strong> ₹{plan.totalProfit}
                  </p>
                </div>

                {/* Upgrade Button */}
                <button
                  disabled={isAlreadyPurchased ? true : false}
                  className={
                    !isAlreadyPurchased
                      ? "py-2 px-4 text-xl bg-blue-500 text-white rounded-full font-semibold hover:bg-blue-600 transition-colors"
                      : "py-2 px-4 text-xl bg-red-500/40 text-white rounded-full font-semibold transition-colors"
                  }
                  onClick={() => handleUpgradeClick(plan)}
                >
                  {isAlreadyPurchased ? "Purchased" : "Upgrade Now"}
                </button>
              </div>
            )
          );
        })}
      </div>

      {/* Modal for Payment */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">
              Pay for {selectedPlan.name}
            </h2>
            {/* QR Code Generation */}
            <QRCodeCanvas
              value={`upi://pay?pa=${upi}&pn=Vivaan Enterprises&am=${selectedPlan.price}&cu=INR`}
              size={290}
              style={{ margin: "auto" }}
            />

            <input
              type="text"
              placeholder="Enter Transaction ID"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
              className="border p-2 w-full mt-2 mb-4"
            />
            <div className="flex justify-between">
              <button
                className="bg-green-500 text-white px-4 py-2 rounded-lg"
                onClick={handleSubmitTransaction}
              >
                Submit Transaction
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Already Purchased Plans */}
      <div className="mt-12 w-full text-center">
        <h2 className="text-2xl font-bold">Purchased Plans</h2>
        <ul className="mt-4 w-full max-w-xl mx-auto bg-gray-100 rounded-lg shadow-md">
          {purchasedPlans?.map((plan, index) => (
            <li
              key={index}
              className={`p-4 border-b text-white  last:border-b-0 rounded-lg shadow-sm ${
                plan.status === "approved"
                  ? "bg-red-500"
                  : plan.status === "rejected"
                  ? "bg-black"
                  : "bg-gray-500"
              }`}
            >
              <div className="flex justify-between items-center">
                {/* Plan Name and Status */}
                <div className="text-left">
                  <span className="text-lg font-semibold">{plan.name}</span>
                  <p
                    className={`text-sm border py-1 rounded uppercase px-2 font-bold ${
                      plan.status === "approved"
                        ? "text-white-600"
                        : plan.status === "rejected"
                        ? "text-red-600"
                        : "text-yellow-600"
                    }`}
                  >
                    {plan.status}
                  </p>
                </div>

                {/* Plan Date */}
                <div className="text-right">
                  <p className="text-sm text-white">
                    <strong>Time:</strong>{" "}
                    {new Date(plan.createdAt).toLocaleDateString()}{" "}
                    {new Date(plan.createdAt).toLocaleTimeString()}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>

        {purchasedPlans?.length === 0 ? (
          <p className=" ">You have Not Purchased Any Plans</p>
        ) : null}
      </div>
    </div>
  );
};

export default UpgradeNow;
