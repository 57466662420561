import React, { useState } from "react";
import "./PopupForm.css"; // External CSS for styling
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import FingerprintJS from "@fingerprintjs/fingerprintjs";
function PopupForm({ isOpen = true, setIsOpen }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    workType: "",
    hasLaptop: "",
    hasMobile: "",
    deviceId: "",
  });

  const [errors, setErrors] = useState({});

  const getDeviceFingerprint = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const deviceId = result.visitorId;
    return deviceId;
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.phone || !/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    if (!formData.workType) {
      newErrors.workType = "Work type is required";
    }
    if (!formData.hasLaptop) {
      newErrors.hasLaptop = "Please select if you have a laptop";
    }
    if (!formData.hasMobile) {
      newErrors.hasMobile = "Please select if you have a mobile";
    }
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please Enter a valid email";
    }
    if (!formData.deviceId) {
      newErrors.email = "This Device can not use for this application";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // return true if no errors
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const deviceId = await getDeviceFingerprint();
    formData.deviceId = deviceId;

    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post("/api/formsubmit", formData);
      if (response.status === 200) {
        toast.success(response.data.message);
        localStorage.setItem("userToken", response?.data?.token);
        setIsOpen(false);
        setTimeout(() => {
          window.location.href = "/user-dashboard";
        }, 2000);
      } else {
        toast.error(response?.data?.message || "Error During Form Submit");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Error During Form Submit");
    }
  };

  const handleClose = () => {
    setIsOpen(false); // Close popup when close button is clicked
  };

  return (
    <>
      {isOpen && (
        <div className="popup-form-container">
          <div className="popup-form">
            <button className="close-btn" onClick={handleClose}>
              &times;
            </button>
            <h2>Join Us!</h2>

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  required
                />
                {errors.name && (
                  <p className="error text-red-700">{errors.name}</p>
                )}
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your Email"
                  required
                />
                {errors.email && (
                  <p className="error text-red-700">{errors.email}</p>
                )}
              </div>

              <div className="form-group">
                <label>Phone Number</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                  required
                />
                {errors.phone && (
                  <p className="error text-red-700">{errors.phone}</p>
                )}
              </div>

              <div className="form-group">
                <label>Type of Work</label>
                <select
                  name="workType"
                  value={formData.workType}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select work type
                  </option>
                  <option value="full-time">Full-time</option>
                  <option value="part-time">Part-time</option>
                </select>
                {errors.workType && (
                  <p className="error text-red-700">{errors.workType}</p>
                )}
              </div>

              <div className="form-group">
                <label>Do you have a Laptop?</label>
                <div className="radio-group">
                  <label>
                    <input
                      type="radio"
                      name="hasLaptop"
                      value="yes"
                      checked={formData.hasLaptop === "yes"}
                      onChange={handleChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="hasLaptop"
                      value="no"
                      checked={formData.hasLaptop === "no"}
                      onChange={handleChange}
                    />
                    No
                  </label>
                </div>
                {errors.hasLaptop && (
                  <p className="error text-red-700">{errors.hasLaptop}</p>
                )}
              </div>

              <div className="form-group">
                <label>Do you have a Mobile?</label>
                <div className="radio-group">
                  <label>
                    <input
                      type="radio"
                      name="hasMobile"
                      value="yes"
                      checked={formData.hasMobile === "yes"}
                      onChange={handleChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="hasMobile"
                      value="no"
                      checked={formData.hasMobile === "no"}
                      onChange={handleChange}
                    />
                    No
                  </label>
                </div>
                {errors.hasMobile && (
                  <p className="error text-red-700">{errors.hasMobile}</p>
                )}
              </div>

              <button type="submit" className="submit-btn">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default PopupForm;
