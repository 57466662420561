import React, { useEffect, useState } from "react";
// import GetBankDetails from "../cards/GetBankDetails";
import PayingCard from "../cards/PayingCard";
import UserDetails from "./UserDetails";
import axios from "axios";
import { toast } from "react-toastify";
import { AiOutlineLoading } from "react-icons/ai";
import { AiOutlinePhone } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
function UserHome({ isAdminView = false }) {
  const [formData, setFormData] = useState(null);

  const onChangeForm = (data) => {
    setFormData(data); // Update the formData when PayingCard changes
  };
  return (
    <div>
      <UserDetails formData={formData} />
      <AlertMarquee />
      <AllAnalyticCard ff={formData} />
      <PayingCard onChange={onChangeForm} />
      {/* <GetBankDetails /> */}
    </div>
  );
}

export default UserHome;

const AllAnalyticCard = ({ ff }) => {
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async () => {
    try {
      const response = await axios.post("/api/user-task-details", {
        userToken: localStorage.getItem("userToken"),
      });
      if (response.status === 200) {
        setFormData(response.data.details);
        setLoading(false);
      } else {
        toast.error(response?.data?.message || "Error loading user details");
      }
    } catch (error) {
      toast.error(error?.message || "Error loading user details");
      setLoading(false);
    }
  };

  // Fetch user details from the backend using Axios
  useEffect(() => {
    fetchUserData();
  }, [ff]);

  // Loading state - show loader animation
  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <AiOutlineLoading className="animate-spin h-16 w-16 text-blue-500" />
        <p className="text-lg font-medium text-gray-500 mt-4">Loading...</p>
      </div>
    );
  }

  // Render 8 animated cards with fetched data
  return (
    <div className="flex my-2  flex-wrap justify-center gap-4  mx-auto">
      {/* Create an array of card data to render */}
      {formData &&
        [
          {
            title: "Today's Completed Tasks",
            value: formData.todayCompletedTasks,
            description: "Number of tasks completed today.",
          },
          {
            title: "Today's Earnings",
            value: `₹${formData.todayEarnings}`,
            description: "Total earnings from tasks completed today.",
          },
          {
            title: "Yesterday's Completed Tasks",
            value: formData.yesterdayCompletedTasks,
            description: "Number of tasks completed yesterday.",
          },
          {
            title: "Yesterday's Earnings",
            value: `₹${formData.yesterdayEarnings}`,
            description: "Total earnings from tasks completed yesterday.",
          },
          {
            title: "Last 7 Days Completed Tasks",
            value: formData.lastSevenDaysCompletedTasks,
            description: "Total tasks completed in the last 7 days.",
          },
          {
            title: "Last 7 Days Earnings",
            value: `₹${formData.lastSevenDaysEarnings}`,
            description:
              "Total earnings from tasks completed in the last 7 days.",
          },
          {
            title: "Most Profitable Plan",
            value: formData.mostProfitablePlan.name,
            description: `Most profitable plan earned: ₹${formData.mostProfitablePlan.earnings}`,
          },
        ].map((data, index) => (
          <div
            key={index}
            className="p-2 px-3 w-[100px] sm:w-[200px] bg-gradient-to-r from-blue-400 to-blue-600 text-white rounded-lg shadow-lg hover:shadow-xl transition-transform transform hover:scale-105 animate-fade-in"
            style={{
              animationDelay: `${index * 0.1}s`,
              animationDuration: "0.6s",
            }}
          >
            <h2 className="text-lg text-yellow-300 font-bold">{data.title}</h2>
            <p className="text-2xl font-semibold">{data.value}</p>
            <p className="text-sm">{data.description}</p>
          </div>
        ))}
    </div>
  );
};

const AlertMarquee = React.memo(() => {
  const [userData, setUserData] = useState(null);
  const fetchUserData = async () => {
    try {
      const response = await axios.post("/api/user-details", {
        userToken: localStorage.getItem("userToken"),
      });
      if (response.status === 200) {
        setUserData(response.data.details);
      } else {
        toast.error(response?.data?.message || "Error loading user details");
      }
    } catch (error) {
      toast.error(error?.message || "Error loading user details");
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    userData?.plans[0]?.name && (
      <div className="relative mt-1 w-full rounded-lg bg-red-500 px-6 py-3 text-white shadow-md">
        <marquee behavior="scroll" direction="left" scrollamount="3">
          <span className="mx-2 flex items-center text-sm">
            {userData?.plans[0].name === "FREE" ? (
              <>
                You Are Using A Free Plan. Please Upgrade To Earn More. To
                Upgrade To Plan A, please Go To UPGRADE NOW tab
              </>
            ) : (
              <>
                You Are Using {userData?.plans[0].name} Plan. To Upgrade It ,
                please Go To UPGRADE NOW tab
              </>
            )}
          </span>
        </marquee>
      </div>
    )
  );
});
