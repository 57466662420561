import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";

function BulkPaymentRequest() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [editingUser, setEditingUser] = useState(null); // User currently being edited
  const [showModal, setShowModal] = useState(false); // Modal state

  useEffect(() => {
 
    fetchFiles();
  }, []);
   const fetchFiles = async () => {
      try {
        const response = await axios.post("/api/get-bulk-transfer", {
          token: localStorage.getItem("token"),
        }); // Adjust the URL as needed
        if (response.status === 200) {
          setFiles(response.data);
        } else {
          setError("Failed to fetch files.");
          toast.error(response.data.message || "Error To fetch files");
        }
      } catch (err) {
        setError("Failed to fetch files.");
        toast.error(err.message || "Error To fetch files");
      } finally {
        setLoading(false);
      }
    };

  // Open modal to edit user
  const openModal = (user) => {
    setEditingUser(user);
    setShowModal(true);
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
    setEditingUser(null);
  };

  // Handle form submission for editing user details
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `/api/edit-status-bulk-withdraw-request/${editingUser._id}`,
        {
          ...editingUser,
          token: localStorage.getItem("token"),
        },
      );
      if (response.status === 200) {
        toast.success("User updated successfully");
        setShowModal(false);
        fetchFiles();
        // Optionally refetch users or update state
      } else {
        toast.error("Failed to update user");
      }
    } catch (error) {
      toast.error("Error updating user: " + error.message);
    }
  };

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="mx-auto max-w-6xl p-4">
      <h2 className="mb-4 text-xl font-bold">
        Latest Bulk Requests [{files?.length}]{" "}
      </h2>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        {files.map((file) => (
          <div
            key={file._id}
            className="overflow-hidden rounded-lg bg-white shadow-lg"
          >
            <img
              src={
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRI7zr-SI2GlQuKVd8z6DBDnr9msl9Q3C60Q&s"
              }
              alt={file.name}
              className="h-32 w-full object-cover"
            />
            <div className="p-4">
              <h3 className="text-lg font-semibold">
                {" "}
                UserName: {file.user.userName}
              </h3>
              <p className="mt-2 text-sm">
                Date:{new Date(file.createdAt).toLocaleTimeString()},{" "}
                {new Date(file.createdAt).toLocaleDateString()}{" "}
              </p>
              <a
                href={`${axios.defaults.baseURL}/uploads/${file.fileName}`}
                className="mt-4 inline-block text-blue-500 hover:underline"
                download
              >
                Download
              </a>

              <button
                onClick={() => openModal(file)}
                className="rounded-lg bg-blue-500 p-2 text-white hover:bg-blue-600"
              >
                <FaEdit className="inline-block" /> {file.status}
              </button>
            </div>



        {/* Modal for Editing */}
        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-lg rounded-lg bg-white p-6 shadow-lg">
              <h3 className="mb-4 text-2xl font-bold">Edit Response</h3>
              <form onSubmit={handleEditSubmit}>
                <select
                  value={editingUser.status}
                  onChange={(e) =>
                    setEditingUser({
                      ...editingUser,
                      status: e.target.value,
                    })
                  }
                  className="my-4 w-full rounded-md border border-gray-300 p-2"
                >
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </select>

                <div className="flex justify-between">
                  <button
                    type="submit"
                    className="rounded-lg bg-green-500 px-4 py-2 text-white hover:bg-green-600"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={closeModal}
                    className="rounded-lg bg-red-500 px-4 py-2 text-white hover:bg-red-600"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}







          </div>
        ))}

      </div>
    </div>
  );
}

export default BulkPaymentRequest;
