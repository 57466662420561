import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { AiOutlineUser } from "react-icons/ai";
import { FaRupeeSign } from "react-icons/fa";
import { BsLockFill } from "react-icons/bs";
import { GiLockedBox, GiSecurityGate } from "react-icons/gi";

function UserDetails({ formData }) {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showGif, setShowGif] = useState(false); // State to control GIF visibility

  const fetchUserData = async () => {
    try {
      const response = await axios.post("/api/user-details", {
        userToken: localStorage.getItem("userToken"),
      });
      if (response.status === 200) {
        setUserData(response.data.details);
        setLoading(false);
        setShowGif(true); // Show the GIF when data is loaded
        setTimeout(() => setShowGif(false), 5000); // Hide the GIF after 2 seconds
      } else {
        toast.error(response?.data?.message || "Error loading user details");
      }
    } catch (error) {
      toast.error(error?.message || "Error loading user details");
    }
  };

  // Fetch user details from the backend using Axios
  useEffect(() => {
    fetchUserData();
  }, [formData]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="h-12 w-12 animate-spin rounded-full border-t-4 border-blue-500"></div>
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="flex h-screen items-center justify-center">
        <p className="text-lg text-gray-700">Failed to load user details.</p>
      </div>
    );
  }

  return (
    <div className="p-6 rounded-lg shadow-xl bg-gradient-to-r from-blue-500 via-purple-600 to-indigo-700 text-white">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-3 text-2xl font-semibold">
          <AiOutlineUser className="text-white bg-gray-800 rounded-full p-1 w-10 h-10" />
          <p>
            Hello, <span className="font-bold">{userData.name}</span>
          </p>
        </div>
        <div className="flex border border-green-500 px-2 rounded-[10px] bg-green-500/60  items-center space-x-3 text-2xl font-semibold">
          <GiLockedBox className="text-white bg-gray-800 rounded-full p-1 w-10 h-10" />
          <div>
            <p className="text-3xl">{userData.plans[0].name}</p>
            <p className="text-sm border-t-orange-600 mt-2 border-b-0 border-x-0 border font-semibold text-yellow-400">
              Completed Task: {userData.plans[0].completedTasks}
            </p>
            <p className="text-sm font-semibold text-yellow-400">
              Available Task:{" "}
              {Number(userData.plans[0].tasks) -
                Number(userData.plans[0].completedTasks)}
            </p>
          </div>
        </div>

        <div className="flex relative items-center space-x-2">
          <FaRupeeSign className="text-yellow-400 text-4xl" />
          <p className="text-3xl font-extrabold animate-bounce">
            {userData.amount}
          </p>
          {/* Add money GIF animation with conditional rendering */}
          {showGif && (
            <img
              src={"https://i.makeagif.com/media/4-23-2015/vsoPPA.gif"}
              alt="Money GIF"
              className="w-[100px] rounded opacity-10  absolute h-12 animate-pulse"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
