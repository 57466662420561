/* import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            vihan enterprises
          </a>{" "}
        </div>
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#featurest" className="page-scroll">
                Features
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Services
              </a>
            </li>
            <li>
              <a href="#plan" className="page-scroll">
                Plane
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                Gallery
              </a>
            </li>
            <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li>

            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
            <li>
              <a href="/login" className="page-scroll">
                Login
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};


 */

import React from "react";

export const Navigation = ({ isOpen = true, setIsOpen }) => {
  return (
    <nav className="bg-white fixed w-full top-0 shadow-md z-50">
      <div className=" mx-auto  py-5 flex justify-around  items-center">
        <div className="flex  items-center">
          <img
            className="m-auto h-[50px] w-[50px] rounded-full"
            src="https://i.ibb.co/Wp2HR7b/DALL-E-2024-10-16-13-27-59-A-modern-and-professional-logo-for-a-work-from-home-website-named-Vivaan.webp"
            alt="logo"
          />
          <a
            className="text-2xl ml-2 font-bold text-gray-800 hover:text-blue-600 transition duration-300"
            href="#page-top"
          >
            Vihan Enterprises
          </a>
        </div>
        <div className="block  lg:hidden">
          <button
            type="button"
            className="text-gray-800 focus:outline-none"
            onClick={() =>
              document.getElementById("menu").classList.toggle("hidden")
            }
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>

          <img
            onClick={() => setIsOpen(!isOpen)}
            className="text-gray-600 ml-4 inline w-[100px] h-[35px] hover:text-blue-600 transition duration-300"
            src="https://www.technofino.in/wp-content/uploads/2022/11/giphy.gif"
            alt=""
          />
        </div>

        <div className="!hidden lg:!block  space-x-8 items-center">
          <a
            href="#features"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Features
          </a>
          <a
            href="#about"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            About
          </a>
          <a
            href="#services"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Services
          </a>
          <a
            href="#plan"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Plans
          </a>
          <a
            href="#portfolio"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Gallery
          </a>
          <a
            href="#testimonials"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Testimonials
          </a>
          <a
            href="#contact"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Contact
          </a>
          <a
            href="/login"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Login
          </a>
          <a
            
            className="text-gray-600  hover:text-blue-600 transition duration-300"
          >
            <img  onClick={() => setIsOpen(!isOpen)}
              className="text-gray-600 inline w-[100px] h-[35px] hover:text-blue-600 transition duration-300"
              src="https://www.technofino.in/wp-content/uploads/2022/11/giphy.gif"
              alt=""
            />
          </a>
        </div>
      </div>

      {/* Mobile Menu */}
      <div id="menu" className="lg:hidden hidden bg-white shadow-md">
        <ul className="flex flex-col space-y-4 p-4">
          <li>
            <a
              onClick={() =>
                document.getElementById("menu").classList.toggle("hidden")
              }
              href="#features"
              className="text-gray-600 hover:text-blue-600 transition duration-300"
            >
              Features
            </a>
          </li>
          <li>
            <a
              onClick={() =>
                document.getElementById("menu").classList.toggle("hidden")
              }
              href="#about"
              className="text-gray-600 hover:text-blue-600 transition duration-300"
            >
              About
            </a>
          </li>
          <li>
            <a
              onClick={() =>
                document.getElementById("menu").classList.toggle("hidden")
              }
              href="#services"
              className="text-gray-600 hover:text-blue-600 transition duration-300"
            >
              Services
            </a>
          </li>
          <li>
            <a
              onClick={() =>
                document.getElementById("menu").classList.toggle("hidden")
              }
              href="#plan"
              className="text-gray-600 hover:text-blue-600 transition duration-300"
            >
              Plans
            </a>
          </li>
          <li>
            <a
              onClick={() =>
                document.getElementById("menu").classList.toggle("hidden")
              }
              href="#portfolio"
              className="text-gray-600 hover:text-blue-600 transition duration-300"
            >
              Gallery
            </a>
          </li>
          <li>
            <a
              onClick={() =>
                document.getElementById("menu").classList.toggle("hidden")
              }
              href="#testimonials"
              className="text-gray-600 hover:text-blue-600 transition duration-300"
            >
              Testimonials
            </a>
          </li>
          <li>
            <a
              onClick={() =>
                document.getElementById("menu").classList.toggle("hidden")
              }
              href="#contact"
              className="text-gray-600 hover:text-blue-600 transition duration-300"
            >
              Contact
            </a>
          </li>
          <li>
            <a
              href="/login"
              className="text-gray-600 hover:text-blue-600 transition duration-300"
            >
              Login
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};
