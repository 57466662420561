import React, { useState } from "react";
import bgVid from "../../data/auth/loginvidcrypto.mp4";
import axios from "axios";
import { toast } from "react-toastify";
const RegistartionForm = () => {
  const [data, setData] = useState({
    userName: "",
    password: "",
  });
  const handleSumit = async () => {
    try {
      const re = await axios.post(`/api/Login`, data);
      if (re.status === 200) {
        localStorage.setItem("userToken", re.data.userToken);
        window.location.href = "/user-dashboard";
        toast.success("Login Successful");
      } else {
        toast.error(re.data.message || "Login Failed");
      }
    } catch (error) {
      toast.error(error.message || "Login Failed");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };
  return (
    <div className="relative flex h-[100vh] items-center justify-center px-5 lg:px-0">
      <video
        className="absolute left-0 top-0 h-full w-full object-cover"
        src={bgVid}
        autoPlay
        loop
        muted
        playsInline
      />
      <div className="relative flex max-w-screen-xl flex-1 justify-center border bg-white bg-opacity-60 shadow sm:rounded-lg">
        <div className="p-6 sm:p-12 lg:w-1/2 xl:w-5/12">
          <div className="flex flex-col items-center">
            <div className="text-center">
              <div className="my-4">
                <img
                  className="m-auto h-[50px] w-[50px] rounded-full"
                  src="https://i.ibb.co/Wp2HR7b/DALL-E-2024-10-16-13-27-59-A-modern-and-professional-logo-for-a-work-from-home-website-named-Vivaan.webp"
                  alt="logo"
                />
              </div>
              <h1 className="text-2xl font-extrabold text-blue-900 xl:text-4xl">
                Welcome Back!
              </h1>
              <p className="text-[12px] text-gray-500">
                Access Your Data Using Your Credentials
              </p>
            </div>
            <div className="mt-8 w-full flex-1">
              <div className="mx-auto flex max-w-xs flex-col gap-4">
                <input
                  className="w-full rounded-lg border border-gray-200 bg-gray-100 px-5 py-3 text-sm font-medium placeholder-gray-500 focus:border-gray-400 focus:bg-white focus:outline-none"
                  type="text"
                  name="userName"
                  value={data.userName}
                  onChange={handleChange}
                  placeholder="Enter your Email Or Mobile"
                />

                <input
                  className="w-full rounded-lg border border-gray-200 bg-gray-100 px-5 py-3 text-sm font-medium placeholder-gray-500 focus:border-gray-400 focus:bg-white focus:outline-none"
                  type="password"
                  name="password"
                  value={data.password}
                  onChange={handleChange}
                  placeholder="Password"
                />
                <button
                  onClick={handleSumit}
                  className="focus:shadow-outline mt-5 flex w-full items-center justify-center rounded-lg bg-blue-900 py-4 font-semibold tracking-wide text-gray-100 transition-all duration-300 ease-in-out hover:bg-indigo-700 focus:outline-none"
                >
                  <svg
                    className="-ml-2 h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                    <circle cx="8.5" cy="7" r="4" />
                    <path d="M20 8v6M23 11h-6" />
                  </svg>
                  <span className="ml-3">Login</span>
                </button>
                <p className="mt-6 text-center text-sm text-gray-600">
                  Forget Password?{" "}
                  <a href="/forget-auth">
                    <span className="font-semibold text-blue-900">
                      click here
                    </span>
                  </a>
                </p>
                <p className=" text-center text-sm text-gray-600">
                  Are You Admin?{" "}
                  <a href="/admin-auth">
                    <span className="font-semibold text-blue-900">
                      Admin Login
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RegistartionForm;
