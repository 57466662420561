import React, { useState } from "react";
import { FiLogOut } from "react-icons/fi"; // For logout icon
import { AiOutlineClose } from "react-icons/ai"; // For close icon

function Logout() {
  const [showModal, setShowModal] = useState(false);

  const handleLogout = () => {
    // Logic to handle logout
    localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <div className="flex min-h-full items-center justify-center bg-gradient-to-r from-indigo-500 to-purple-600">
      <div className="rounded-lg bg-white p-8 text-center shadow-lg">
        <h2 className="mb-4 text-2xl font-bold text-gray-800">
          Are you sure you want to log out?
        </h2>
        <button
          onClick={() => setShowModal(true)}
          className="inline-flex items-center rounded-full bg-red-500 px-4 py-2 font-semibold text-white shadow-lg transition duration-300 hover:bg-red-600"
        >
          <FiLogOut className="mr-2 text-xl" />
          Logout
        </button>
      </div>

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-full max-w-sm rounded-lg bg-white p-6 shadow-lg">
            <AiOutlineClose
              className="absolute right-2 top-2 cursor-pointer text-gray-500 hover:text-gray-700"
              onClick={() => setShowModal(false)}
              size={24}
            />
            <h3 className="mb-4 text-xl font-semibold text-gray-800">
              Confirm Logout
            </h3>
            <p className="mb-6 text-gray-600">
              Are you sure you want to log out?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowModal(false)}
                className="rounded-full bg-gray-200 px-4 py-2 transition hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleLogout}
                className="rounded-full bg-red-500 px-4 py-2 text-white transition hover:bg-red-600"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Logout;
