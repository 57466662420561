// import React from "react";
// import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
// import { FaWhatsapp } from "react-icons/fa";
// import FAQ from "./Faq";
// import PlansComparison from "../../components/PlansComparison";

// function Support() {
//   return (
//     <div className="flex min-h-screen flex-col items-center justify-center bg-gradient-to-b from-blue-500 to-blue-700 ">
//       <div className="w-full max-w-4xl rounded-lg bg-white p-8 text-center shadow-xl">
//         <h1 className="mb-6 text-3xl font-bold text-blue-600">Support</h1>
//         <p className="mb-8 text-lg text-gray-600">
//           We're here to help! Reach out to us for any assistance you need.
//         </p>

//         <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
//           {/* Call Support */}
//           <div className="rounded-lg bg-blue-100 p-6 shadow-md transition-all hover:bg-blue-200">
//             <AiOutlinePhone className="mx-auto mb-4 text-4xl text-blue-600" />
//             <h2 className="mb-2 text-xl font-semibold text-gray-700">
//               Call Us
//             </h2>
//             <p className="mb-4 text-gray-500">+91 72090 58182</p>
//             <a href="tel:+917209058182">
//               <button className="rounded-lg bg-blue-600 px-6 py-2 text-white transition-colors hover:bg-blue-700">
//                 Call Now
//               </button>
//             </a>
//           </div>

//           {/* WhatsApp Support */}
//           <div className="rounded-lg bg-blue-100 p-6 shadow-md transition-all hover:bg-blue-200">
//             <FaWhatsapp className="mx-auto mb-4 text-4xl text-green-600" />
//             <h2 className="mb-2 text-xl font-semibold text-gray-700">
//               WhatsApp Us
//             </h2>
//             <p className="mb-4 text-gray-500">+91 72090 58182</p>
//             <a
//               href="https://wa.me/917209058182"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <button className="rounded-lg bg-green-600 px-6 py-2 text-white transition-colors hover:bg-green-700">
//                 WhatsApp Now
//               </button>
//             </a>
//             <p className="mb-4 text-gray-500">Recommended</p>
//           </div>
//         </div>

//         <div className="mt-12 text-gray-500">
//           <p>
//             For more information, feel free to contact us. We strive to provide
//             the best service possible.
//           </p>
//         </div>
//       </div>

//     </div>
//   );
// }

// export default Support;
import React, { useEffect, useState } from "react";
import axios from "axios";
import { FiSend } from "react-icons/fi";
import { BiMessageSquareDetail } from "react-icons/bi";
import { MdSubject } from "react-icons/md";
import { toast } from "react-toastify";

function Support() {
  const [ticket, setTicket] = useState({
    subject: "",
    description: "",
  });

  const handleChange = (e) => {
    setTicket({ ...ticket, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/submittickets", {
        ...ticket,
        userToken: localStorage.getItem("userToken"),
      });
      if (response.status === 200) {
        getTicketSubmit();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting ticket", error);
    }
  };
  const [tickets, setTickets] = useState(null);

  useEffect(() => {
    getTicketSubmit();
  }, []);
  const getTicketSubmit = async () => {
    try {
      const response = await axios.post("/api/gettickets", {
        userToken: localStorage.getItem("userToken"),
      });
      if (response.status === 200) {
        setTickets(response.data);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error submitting ticket", error);
    }
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-purple-500 via-pink-500 to-red-500">
      <div className="bg-white shadow-lg rounded-lg p-10 w-full max-w-lg">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Raise a Support Ticket
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="flex items-center space-x-2">
            <MdSubject className="text-2xl text-purple-600" />
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              value={ticket.subject}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              required
            />
          </div>

          <div className="flex items-center space-x-2">
            <BiMessageSquareDetail className="text-2xl text-purple-600" />
            <textarea
              name="description"
              placeholder="Describe your issue"
              value={ticket.description}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              required
            />
          </div>

          <button
            type="submit"
            className="flex items-center justify-center w-full px-4 py-2 text-white bg-purple-600 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
          >
            <FiSend className="mr-2" /> Submit Ticket
          </button>
        </form>
      </div>

      {/* Already Purchased Plans */}
      <div className="mt-12 w-full text-center">
        <h2 className="text-2xl font-bold">Old Tickets</h2>
        <ul className="mt-4 w-full max-w-xl mx-auto bg-gray-100 rounded-lg shadow-md">
          {tickets?.map((ticket, index) => (
            <li
              key={index}
              className={`p-4 border-b text-white  last:border-b-0 rounded-lg shadow-sm ${
                ticket.status === "resolved" ? "bg-red-500" : "bg-gray-500"
              }`}
            >
              <div className="flex justify-between items-center">
                {/* Plan Name and Status */}
                <div className="text-left">
                  <span className="text-lg  font-bold">{ticket.subject}</span>
                  <p
                    className={`text-sm border py-1 rounded uppercase px-2 font-bold ${
                      ticket.status === "resolved"
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                  >
                    {ticket.status}
                  </p>
                  <div>{ticket.description}</div>
                  {ticket?.reply !== "" && <div>{ticket.reply}</div>}
                </div>

                {/* Plan Date */}
                <div className="text-right">
                  <p className="text-sm text-white">
                    <strong>Time:</strong>{" "}
                    {new Date(ticket.createdAt).toLocaleDateString()}{" "}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>

        {tickets?.length === 0 ? (
          <p className=" ">You have Not created Any ticket</p>
        ) : null}
      </div>
    </div>
  );
}

export default Support;
