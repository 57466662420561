import React from "react";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";

function PaymentGateway() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center gap-y-4 bg-gradient-to-b from-blue-500 to-blue-700 p-6">
      <p className="w-full rounded-xl bg-red-500 p-4 text-center font-bold text-white shadow-lg">
        <span className="underline">Alert:</span> Kindly maintain your minimum
        balance of ₹1,00,000, or get in touch with our support team.
      </p>
      <div className="w-full max-w-4xl rounded-lg bg-white p-8 text-center shadow-xl">
        <h1 className="mb-6 text-3xl font-bold text-blue-600">get in touch </h1>
        <p className="mb-8 text-lg text-gray-600">
          We're here to help! Reach out to us for any assistance you need.
        </p>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          {/* Call Support */}
          <div className="rounded-lg bg-blue-500 p-6 shadow-md transition-all hover:bg-black">
            <AiOutlinePhone className="mx-auto mb-4 text-4xl text-white" />
            <h2 className="mb-2 text-xl font-semibold text-white">Call Us</h2>
            <p className="mb-4 text-white">+91 72090 58182</p>
            <a href="tel:+917209058182">
              <button className="rounded-lg bg-blue-600 px-6 py-2 text-white transition-colors hover:bg-blue-700">
                Call Now
              </button>
            </a>
          </div>

          {/* Email Support
          <div className="bg-blue-100 p-6 rounded-lg shadow-md hover:bg-blue-200 transition-all">
            <AiOutlineMail className="text-blue-600 text-4xl mb-4 mx-auto" />
            <h2 className="text-xl font-semibold text-gray-700 mb-2">Email Us</h2>
            <p className="text-gray-500 mb-4">support@virtualbank.com</p>
            <a href="mailto:support@virtualbank.com">
              <button className="bg-blue-600 text-white py-2 px-6 rounded-lg hover:bg-blue-700 transition-colors">
                Send Email
              </button>
            </a>
          </div> */}

          {/* WhatsApp Support */}
          <div className="rounded-lg bg-blue-500 p-6 shadow-md transition-all hover:bg-black">
            <FaWhatsapp className="mx-auto mb-4 text-4xl text-white" />
            <h2 className="mb-2 text-xl font-semibold text-white">
              WhatsApp Us
            </h2>
            <p className="mb-4 text-white">+91 72090 58182</p>
            <a
              href="https://wa.me/917209058182"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="rounded-lg bg-green-600 px-6 py-2 text-white transition-colors hover:bg-green-700">
                WhatsApp Now
              </button>
            </a>
            <p className="mb-4 text-white">Recommended</p>
          </div>
        </div>

        <div className="mt-12 text-gray-500">
          <p>
            For more information, feel free to contact us. We strive to provide
            the best service possible.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PaymentGateway;
