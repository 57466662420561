import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

function PaymentHistory() {
  const [withdrawals, setWithdrawals] = useState([]);

  useEffect(() => {
    const fetchWithdrawals = async () => {
      try {
        const res = await axios.post(`/api/withdrawals-history`, {
          userToken: localStorage.getItem("userToken"),
        });
        if (res.status === 200) {
          setWithdrawals(res.data.withdrawals);
        } else {
          toast.error(
            res.data.message || "Something went wrong with withdrawals history",
          );
        }
      } catch (error) {
        toast.error(
          error.message || "Something went wrong with withdrawals history",
        );
      }
    };

    fetchWithdrawals();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h2 className="mb-6 text-2xl font-bold text-gray-800">Payment History</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white text-left text-sm font-light shadow-lg">
          <thead className="border-b bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-4 text-gray-700">
                Amount
              </th>
              <th scope="col" className="px-6 py-4 text-gray-700">
                Payment Method
              </th>
              <th scope="col" className="px-6 py-4 text-gray-700">
                Date
              </th>
              <th scope="col" className="px-6 py-4 text-gray-700">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {withdrawals.length > 0 ? (
              withdrawals.map((withdrawal) => (
                <tr key={withdrawal._id} className="border-b hover:bg-gray-100">
                  <td className="px-6 py-4">{`₹${withdrawal.amount}`}</td>
                  <td className="px-6 py-4 capitalize">
                    {withdrawal.paymentMethod}
                  </td>
                  <td className="px-6 py-4">
                    {new Date(withdrawal.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 font-semibold text-green-600">
                    {withdrawal.status || "Pending"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="px-6 py-4 text-center">
                  No withdrawals found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PaymentHistory;
