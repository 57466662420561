import React, { useState } from "react";
import axios from "axios";
import BulkStatusFromUser from "./BulkStatusFromUser";

function BulkPayout() {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      setFile(selectedFile);
      setMessage(""); // Clear any previous messages
    } else {
      setMessage("Please select a valid CSV file.");
      setFile(null);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!file) {
      setMessage("No file selected.");
      return;
    }

    const formData = new FormData();
    formData.append("csvFile", file);
    formData.append("userToken", localStorage.getItem("userToken"));
    try {
      const response = await axios.post("/api/bulk-transfer", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setMessage(response.data.message);
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage("Error uploading file. Please try again.");
    }
  };

  return (
    <div className="flex min-h-screen  flex-col items-center justify-center bg-gradient-to-r from-blue-200 to-purple-300 ">
      <div className="w-full max-w-lg transform rounded-lg bg-white p-8 shadow-lg transition-all duration-500 hover:scale-105">
        <h2 className="mb-6 text-center  text-sm sm:text-2xl font-bold text-gray-800">
          Bulk CSV Upload for Transfer
        </h2>
        <form onSubmit={handleUpload}>
          <div className="mb-5">
            <label className="mb-2 block  text-sm sm:text-2xl text-gray-600" htmlFor="csvFile">
              Upload your CSV file
            </label>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="block w-full rounded-md border border-gray-300 p-3 transition duration-200 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            type="submit"
            className="w-full transform rounded-md bg-blue-600 py-3 font-semibold text-white transition duration-200 hover:scale-105 hover:bg-blue-700"
          >
            Upload CSV
          </button>
        </form>
        {message && (
          <p
            className={`mt-4 text-center font-medium ${
              message.includes("Error") ? "text-red-500" : "text-green-500"
            }`}
          >
            {message}
          </p>
        )}
      </div>
      <BulkStatusFromUser />
    </div>
  );
}

export default BulkPayout;
