import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ToastContainer, toast } from "react-toastify";
import App from './App';
import axios from "axios";
import * as serviceWorker from './serviceWorker';
//  axios.defaults.baseURL = "http://localhost:5000";
 axios.defaults.baseURL = "https://wfh.itcportals.net";
ReactDOM.render(
  <React.StrictMode>
    <App />
    <ToastContainer
      position="top-left"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      style={{
        zIndex: 100000000,
      }}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
