import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import PopupForm from "./components/PoupupForm";
import Privacy from "./components/policy/privacy";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import PlansComparison from "./components/PlansComparison";
import Keywords from "./components/Keywords";
import RegistartionForm from "./auth/components/Login";
import AdminAuth from "./auth/pages/AdminAuth";
import AdminForUser from "./auth/pages/AdminForUser";
import FloatingButtons from "./components/FloatingButtons ";
import Dashboard from "./auth/pages/Admin";
import Payment from "./auth/cards/Payment";
import ForgetPassword from "./auth/pages/ForgetPassword";
import RandomPopup from "./effects/effect1";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          {/* Route for the home page */}
          <Route
            path="/"
            element={
              <>
                <RandomPopup />
                <PopupForm isOpen={isOpen} setIsOpen={setIsOpen} />
                <Navigation isOpen={isOpen} setIsOpen={setIsOpen} />
                <Header
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  data={landingPageData.Header}
                />
                <FloatingButtons isOpen={isOpen} setIsOpen={setIsOpen} />
                <Features data={landingPageData.Features} />
                <About data={landingPageData.About} />
                <Services data={landingPageData.Services} />
                <PlansComparison isOpen={isOpen} setIsOpen={setIsOpen} />
                <Gallery data={landingPageData.Gallery} />
                <Testimonials data={landingPageData.Testimonials} />
                <Contact data={landingPageData.Contact} />
                <Keywords isOpen={isOpen} setIsOpen={setIsOpen} />
              </>
            }
          />
          {/* Route for the privacy policy page */}
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/forget-auth" element={<ForgetPassword />} />
          <Route path="/login" element={<RegistartionForm />} />
          <Route path="/admin-auth" element={<AdminAuth />} />
          <Route path="/admin" element={<Dashboard />} />
          <Route path="/user-dashboard" element={<AdminForUser />} />

          {/* You can add more routes as needed */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
