// Function to generate a random integer between min and max
const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

// Function to shuffle an array
const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

// Function to generate random agency name
const getRandomAgencyName = () => {
  const agencyNames = [
    'Alpha', 'Beta', 'Gamma', 'Delta', 'Epsilon', 'Zeta', 'Omega', 'Sigma', 'Prime', 'NextGen', 'Infinity', 'Skyline',
    'Horizon', 'Apex', 'Vector', 'Fusion', 'Quantum', 'Aurora', 'Pinnacle', 'Catalyst', 'Vanguard', 'Summit', 'Lunar',
    'Solaris', 'Phoenix', 'Titan', 'Neptune', 'Legacy', 'Ascend', 'Matrix', 'Impact', 'Nova', 'Echo', 'Nimbus', 'Sapphire',
    'Emerald', 'Crimson', 'Ivory', 'Maverick', 'Crescent', 'Axion', 'Zephyr', 'Atlas', 'Orbit', 'Vortex', 'Vertex',
    'Arcadia', 'Endeavor', 'Pioneer', 'Blaze', 'Velocity', 'Stratos', 'Frontier', 'Prism', 'Stellar', 'Nimbus', 'Aurora',
    'Blizzard', 'Comet', 'Eclipse', 'Firefly', 'Galaxy', 'Helix', 'Ignite', 'Jupiter', 'Kinetic', 'Lunar', 'Mercury',
    'Nebula', 'Odyssey', 'Pulse', 'Radiant', 'Spectrum', 'Tidal', 'Unity', 'Venture', 'Whirlwind', 'Xenon', 'Zeus',
    'Borealis', 'Constellation', 'Discovery', 'Falcon', 'Glacier', 'Hyperion', 'Impulse', 'Orion', 'Pulsar', 'Quest',
    'Revolution', 'Sierra', 'Tempest', 'Ultrawave', 'Vigilance'
  ];
  return `${shuffleArray(agencyNames)[0]} Agency`;
};

// Function to generate a random address
const getRandomAddress = () => {
  const streetNames = [
    'Main St', 'Broadway', 'Elm St', 'Maple Ave', 'Oak Blvd', 'Pine Ln', 'Cedar Rd', 'Sunset Blvd', 'River Dr', 'Park Ave',
    'Lakeview Dr', 'Ocean Blvd', 'Hilltop Rd', 'Birch Ln', 'Willow St', 'Forest Dr', 'Magnolia Ave', 'Spruce Ct', 'Highland Dr',
    'Orchard Rd', 'Valley View', 'Hillside St', 'Mountain Dr', 'Aspen Way', 'Cherry St', 'Poplar Ln', 'Fir St', 'Cypress Rd',
    'Juniper Ave', 'Sycamore St', 'Redwood Blvd', 'Palm St', 'Bayview Dr', 'Evergreen Rd', 'Pinehurst Dr', 'Silver St',
    'Hawthorne Ln', 'Meadow Rd', 'Bridgeport Blvd', 'Rosewood Ave', 'Kingfisher Ct', 'Deer Park Rd', 'Meadowbrook Ln',
    'Fox Run Rd', 'Aspen Ln', 'Woodland Dr', 'Waterfall Ct', 'Ironwood Rd', 'Ridgeview Ct', 'Greenwood Blvd', 'Cardinal St',
    'Summit Ave', 'Vista Dr', 'Canyon Rd', 'Sequoia Ave', 'Chestnut St', 'Lincoln Blvd', 'Bluebird Ln', 'Falcon Dr'
  ];
  const cities = [
    'New York', 'Los Angeles', 'Chicago', 'Houston', 'Phoenix', 'Philadelphia', 'San Antonio', 'San Diego', 'Dallas', 'Austin',
    'San Francisco', 'Seattle', 'Miami', 'Las Vegas', 'Denver', 'Atlanta', 'Boston', 'Detroit', 'Portland', 'Orlando',
    'Kansas City', 'Tampa', 'Pittsburgh', 'Salt Lake City', 'Cleveland', 'Baltimore', 'Milwaukee', 'Nashville', 'Cincinnati',
    'Indianapolis', 'Raleigh', 'Charlotte', 'Omaha', 'Sacramento', 'Columbus', 'Minneapolis', 'Buffalo', 'Fresno', 'Fort Worth',
    'St. Louis', 'San Jose', 'Riverside', 'Mesa', 'Tucson', 'Long Beach', 'Oakland', 'Santa Clara', 'Bakersfield', 'Honolulu',
    'Anchorage', 'Boise', 'Cheyenne', 'Fargo', 'Helena', 'Jackson', 'Lansing', 'Madison', 'Olympia', 'Richmond', 'Salem',
    'Topeka', 'Little Rock', 'Boulder', 'Charleston', 'Des Moines', 'Dover', 'Frankfort', 'Hartford', 'Juneau', 'Montgomery',
    'Albany', 'Augusta', 'Concord', 'Harrisburg', 'Jefferson City', 'Lincoln', 'Montpelier', 'Santa Fe', 'Trenton', 'Pierre'
  ];
  return `${getRandomInt(100, 999)} ${shuffleArray(streetNames)[0]}, ${shuffleArray(cities)[0]}, USA`;
};

// Function to generate a random international mobile number
const getRandomPhoneNumber = () => {
  const countryCode = `+${getRandomInt(1, 99)}`;
  const number = `${getRandomInt(100, 999)}-${getRandomInt(100, 999)}-${getRandomInt(1000, 9999)}`;
  return `${countryCode} ${number}`;
};

// Function to generate a random email
const getRandomEmail = () => {
  const emailProviders = [
    'gmail.com', 'yahoo.com', 'outlook.com', 'example.com', 'hotmail.com', 'mail.com', 'protonmail.com', 'zoho.com',
    'icloud.com', 'fastmail.com', 'hushmail.com', 'aol.com', 'inbox.com', 'gmx.com', 'posteo.net', 'mail.ru'
  ];
  const name = `user${getRandomInt(1000, 9999)}`;
  return `${name}@${shuffleArray(emailProviders)[0]}`;
};

// Function to generate a random website URL
const getRandomWebsite = () => {
  const domains = ['com', 'net', 'org', 'info', 'co', 'tech', 'biz', 'online', 'us', 'io', 'ai', 'me', 'cloud'];
  const name = `website${getRandomInt(100, 999)}`;
  return `www.${name}.${shuffleArray(domains)[0]}`;
};

// Function to generate a random past date (from 2022-01-01 to today)
const getRandomPastDate = () => {
  const startDate = new Date('2022-01-01');
  const today = new Date();
  const randomDate = new Date(startDate.getTime() + Math.random() * (today.getTime() - startDate.getTime()));
  return randomDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD
};

// Function to generate a random time in the format 10:00 AM/PM
const getRandomTime = () => {
  const hours = getRandomInt(1, 12);
  const minutes = getRandomInt(0, 59).toString().padStart(2, '0');
  const period = getRandomInt(0, 1) === 0 ? 'AM' : 'PM';
  return `${hours}:${minutes} ${period}`;
};

// Function to generate random tags
const getRandomTags = () => {
  const tags = [
    'urgent', 'high-priority', 'new', 'in-progress', 'pending', 'completed', 'on-hold', 'review', 'escalated', 'archived',
    'follow-up', 'feedback', 'research', 'development', 'maintenance', 'critical', 'long-term', 'short-term', 'planning',
    'design', 'testing', 'optimization', 'security', 'sustainability', 'training', 'communication', 'collaboration',
    'innovation', 'creativity', 'efficiency', 'strategy', 'analytics', 'reporting', 'growth', 'expansion', 'evaluation',
    'compliance', 'onboarding', 'adjustments', 'production', 'quality', 'integrity', 'scalability', 'automation', 'transparency'
  ];
  const numTags = getRandomInt(1, 4);
  const selectedTags = shuffleArray(tags).slice(0, numTags);
  return selectedTags;
};

// Generating the task array with shuffled data and 150 variations
export const taskArray = [];

for (let i = 1; i <= 40000; i++) { // You can adjust the number as needed
  taskArray.push({
    id: i,
    name: getRandomAgencyName(),
    fullAddress: getRandomAddress(),
    mobileNumber: getRandomPhoneNumber(),
    email: getRandomEmail(),
    website: getRandomWebsite(),
    rating:   parseFloat((Math.random() * 4 + 1).toFixed(1)),
    date: getRandomPastDate(),
    time: getRandomTime(),
    tags: getRandomTags()
  });
}


