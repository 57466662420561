import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

function AddBankAccount() {
  const [accountHolder, setAccountHolder] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [branchName, setBranchName] = useState("");
  const [bankName, setBankName] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send the new bank account data to the backend
    try {
      const re = await axios.post("/api/add-bank-account", {
        accountHolder,
        accountNumber,
        ifscCode,
        branchName,
        bankName,
        token: localStorage.getItem("token"),
      });
      if (re.status === 200) {
        toast.success(re.data.message);
        setShowModal(true);
      } else {
        toast.success(re.data.message);
      }
    } catch (error) {
      toast.success(error.message);
    }
  };
  return (
    <div className="flex min-h-screen items-center justify-center bg-gradient-to-r from-blue-400 to-purple-500">
      <div className="w-full max-w-md rounded-lg bg-white p-8 shadow-xl">
        <h2 className="mb-6 text-center text-3xl font-bold text-gray-700">
          Add Bank Account
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="mb-2 block font-semibold text-gray-600">
              Account Holder Name
            </label>
            <input
              type="text"
              value={accountHolder}
              onChange={(e) => setAccountHolder(e.target.value)}
              className="w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-semibold text-gray-600">
              Account Number
            </label>
            <input
              type="text"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              className="w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-semibold text-gray-600">
              Branch Address
            </label>
            <input
              type="text"
              value={branchName}
              onChange={(e) => setBranchName(e.target.value)}
              className="w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-semibold text-gray-600">
              IFSC Code
            </label>
            <input
              type="text"
              value={ifscCode}
              onChange={(e) => setIfscCode(e.target.value)}
              className="w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-semibold text-gray-600">
              Bank Name
            </label>
            <input
              type="text"
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
              className="w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-500"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full rounded-lg bg-purple-600 px-4 py-2 font-bold text-white transition duration-300 hover:bg-purple-700"
          >
            Add Account
          </button>
        </form>
      </div>

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="relative w-full max-w-lg rounded-lg bg-white p-6 shadow-xl">
            <h3 className="mb-4 text-2xl font-bold text-green-600">
              Bank Account Added!
            </h3>
            <p className="mb-6 text-gray-700">
              The following bank account has been added successfully:
            </p>
            <p className="text-gray-800">
              <strong>Account Holder:</strong> {accountHolder}
            </p>
            <p className="text-gray-800">
              <strong>Account Number:</strong> {accountNumber}
            </p>
            <p className="text-gray-800">
              <strong>IFSC Code:</strong> {ifscCode}
            </p>
            <p className="text-gray-800">
              <strong>Branch Address:</strong> {branchName}
            </p>
            <p className="mb-6 text-gray-800">
              <strong>Bank Name:</strong> {bankName}
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => setShowModal(false)}
                className="rounded-lg bg-purple-600 px-4 py-2 font-semibold text-white transition hover:bg-purple-700"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddBankAccount;
