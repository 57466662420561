// export default Support;
import React, { useEffect, useState } from "react";
import axios from "axios";
import { FiSend } from "react-icons/fi";
import { BiMessageSquareDetail } from "react-icons/bi";
import { MdSubject } from "react-icons/md";
import { toast } from "react-toastify";

function EditPaymentUPI() {
  const [ticket, setTicket] = useState({
    UPI: "",
  });

  const handleChange = (e) => {
    setTicket({ ...ticket, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/submitUpiFromAdmin", {
        token: localStorage.getItem("token"),
        ...ticket,
      });
      if (response.status === 200) {
        getTicketSubmit();
        setTicket({ UPI: "" });
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting ticket", error);
    }
  };
  const [tickets, setTickets] = useState(null);

  useEffect(() => {
    getTicketSubmit();
  }, []);
  const getTicketSubmit = async () => {
    try {
      const response = await axios.post("/api/getUPIFromAdmin", {
        token: localStorage.getItem("token"),
      });
      if (response.status === 200) {
        setTickets(response.data);
        console.log(response.data)
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error submitting ticket", error);
    }
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-purple-500 via-pink-500 to-red-500">
      <div className="bg-white shadow-lg rounded-lg p-10 w-full max-w-lg">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Update Your UPI
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="flex items-center space-x-2">
            <MdSubject className="text-2xl text-purple-600" />
            <input
              type="text"
              name="UPI"
              placeholder="UPI"
              value={ticket.UPI}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              required
            />
          </div>

          <button
            type="submit"
            className="flex items-center justify-center w-full px-4 py-2 text-white bg-purple-600 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
          >
            <FiSend className="mr-2" /> ADD UPI
          </button>
        </form>
      </div>

      {/* Already Purchased Plans */}
      <div className="mt-12 w-full text-center">
        <h2 className="text-2xl font-bold">Old UPIs</h2>
        <ul className="mt-4 w-full max-w-xl mx-auto bg-gray-100 rounded-lg shadow-md">
          {tickets?.map((ticket, index) => (
            <li
              key={index}
              className={`p-4 border-b text-white  last:border-b-0 rounded-lg shadow-sm ${
                ticket.status === "resolved" ? "bg-red-500" : "bg-gray-500"
              }`}
            >
              <div className="flex justify-between items-center">
                {/* Plan Name and Status */}
                <div className="text-left">
                  <span className="text-lg  font-bold">{ticket.upi}</span>
                </div>

                {/* Plan Date */}
                <div className="text-right">
                  <p className="text-sm text-white">
                    <strong>Time:</strong>{" "}
                    {new Date(ticket.createdAt).toLocaleDateString()}{" "}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>

        {tickets?.length === 0 ? (
          <p className=" ">You have Not created Any Upis</p>
        ) : null}
      </div>
    </div>
  );
}

export default EditPaymentUPI;
