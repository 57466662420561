import React, { useState } from "react";
import { FaWindowClose } from "react-icons/fa";
import { IoReorderThree } from "react-icons/io5";
import Logout from "../components/Logout";

import UserHome from "../components/UserHome";
import Support from "../cards/Support";
import Withrawal from "../cards/Withrawal";
import PaymentGateway from "../cards/PaymentGateway";
import QrCode from "../cards/QrCode";
import PaymentHistory from "../cards/PaymentHistory";
import BulkPayout from "../cards/BulkPayout";
import UpgradeNow from "../components/UpgradeNow";
import RandomPopup from "../../effects/effect1";
const AdminForUser = () => {
  const [activeTab, setActiveTab] = useState("Dashboard");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const tabs = [
    "Dashboard",
    "Upgrade Now",
    "Withrawal",

    // "Qr Code",
    // "Payment Gateway",
    "Payment History",
    "Support",
    "Logout",
  ];

  const renderContent = () => {
    switch (activeTab) {
      case "Dashboard":
        return <UserHome />;
      case "Upgrade Now":
        return <UpgradeNow />;
      case "Withrawal":
        return <Withrawal />;
      case "Qr Code":
        return <QrCode />;
      case "Withrawal":
        return <Withrawal />;
      case "Bulk Payout":
        return <BulkPayout />;
      case "Payment Gateway":
        return <PaymentGateway />;
      case "Payment History":
        return <PaymentHistory />;
      case "Support":
        return <Support />;
      case "Logout":
        return <Logout />;
      default:
        return <div>Welcome to the Dashboard</div>;
    }
  };

  return (
    <div className="flex h-screen">
      <RandomPopup />
      {/* Sidebar for Desktop */}
      <div className="md:!block !hidden h-full w-64 bg-gray-800 text-white md:flex md:!flex-col">
        <h1 className="border-b-2 border-gray-700 p-2 text-2xl font-bold">
          Dashboard
        </h1>
        <ul>
          {tabs.map((tab) => (
            <li
              key={tab}
              className={`cursor-pointer p-4 hover:bg-gray-700 ${
                activeTab === tab ? "bg-gray-700" : ""
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>

      {/* Mobile Sidebar Toggle Button */}
      <div className="flex h-12 w-14 items-center justify-center bg-gray-800 p-3 text-white md:hidden">
        <button
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className="text-white  focus:outline-none"
        >
          {sidebarOpen ? <FaWindowClose size={24}  /> : <IoReorderThree size={34} />}
        </button>
      </div>
      {/* Mobile Sidebar (Slide-in) */}
      <div
        className={`fixed left-0 top-0 z-50 h-full w-64 transform space-y-4 bg-gray-800 p-4 text-white transition-transform md:hidden ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <h1 className="border-b-2 border-gray-700 pb-2 text-2xl font-bold">
          Dashboard
        </h1> 
        <ul>
          {tabs.map((tab) => (
            <li
              key={tab}
              className={`cursor-pointer p-4 hover:bg-gray-700 ${
                activeTab === tab ? "bg-gray-700" : ""
              }`}
              onClick={() => {
                setActiveTab(tab);
                setSidebarOpen(false); // Close sidebar after selecting a tab
              }}
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>

      {/* Overlay for mobile when sidebar is open */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 z-40 bg-black opacity-50 md:hidden"
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}

      {/* Content Area */}
      <div className="h-full flex-1 overflow-y-auto ">
        <h2 className="mb-4 text-2xl font-bold">{activeTab}</h2>
        <div className="h-full rounded-lg bg-white  ">{renderContent()}</div>
      </div>
    </div>
  );
};

export default AdminForUser;
