import React, { useEffect, useState } from "react";
import axios from "axios"; // For making requests to the backend
import { toast } from "react-toastify";

function AddCustomer() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);

  const generateCredentials = () => {
    const randomUsername = `user${Math.floor(Math.random() * 10000)}`; // e.g., "user1234"
    const randomPassword = Math.floor(
      100000 + Math.random() * 900000,
    ).toString(); // 6-digit number as string
    setUsername(randomUsername);
    setPassword(randomPassword);
  };

  // Use effect to generate credentials on page load
  useEffect(() => {
    generateCredentials();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const re = await axios.post(`/api/create-user`, {
        userName: username,
        password,
        token: localStorage.getItem("token"),
      });
      if (re.status === 200) {
        toast.success("Login Successful");
        setShowModal(true);
      } else {
        toast.error(re.data.message || "Creating User Failed");
      }
    } catch (error) {
      toast.error(error.message || "Creating User Failed");
    }
  };

  function CopyContent() {
    const content = `
      User Created Successfully!

      Dear Sir/Madam,
      Your Virtual Current Account Number Has Been Activated!
      💲Now You Can Start Transacting Smoothly!

      URL: ${window.location.origin}/login
      Username: ${username}
      Password: ${password}

      Ensure to inform the user about their credentials and keep them secure.
    `;

    navigator.clipboard
      .writeText(content)
      .then(() => {
        toast.success("content copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Failed to copy content. Please try again.");
      });
  }

  return (
    <div className="flex min-h-screen items-center justify-center bg-gradient-to-r from-green-400 to-blue-500">
      <div className="w-full max-w-md rounded-lg bg-white p-8 shadow-xl">
        <h2 className="mb-6 text-center  text-sm sm:text-2xl font-bold text-gray-700">
          Create New User
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="mb-2 block font-semibold text-gray-600">
              Username
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block font-semibold text-gray-600">
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full rounded-lg bg-blue-600 px-4 py-2 font-bold text-white transition duration-300 hover:bg-blue-700"
          >
            Create User
          </button>
        </form>
      </div>

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="relative w-full max-w-lg rounded-lg bg-white p-6 shadow-xl">
            <h3 className="mb-4 text-2xl font-bold text-green-600">
              User Created Successfully!
            </h3>
            <p className="mb-6 text-gray-700">Dear Sir/Madam,</p>
            <p className="mb-6 text-green-500">
              Your Virtual Current Acount Number Has Been Activated!
            </p>
            <p className="mb-6 text-green-500">
              💲Now You Can Start Transacting Smoothly!
            </p>

            <p className="mb-6 text-gray-800">
              <strong>URL:</strong>{" "}
              <a href={`${window.location.origin}/login}`}>
                {window.location.origin}/login
              </a>
            </p>
            <p className="text-gray-800">
              <strong>Username:</strong> {username}
            </p>
            <p className="mb-6 text-gray-800">
              <strong>Password:</strong> {password}
            </p>

            <p className="mb-6 italic text-gray-700">
              Ensure to inform the user about their credentials and keep them
              secure.
            </p>
            <div className="flex justify-end gap-x-3">
              <button
                onClick={() => CopyContent()}
                className="rounded-lg bg-blue-600 px-4 py-2 font-semibold text-white transition hover:bg-blue-700"
              >
                Copy
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="rounded-lg bg-blue-600 px-4 py-2 font-semibold text-white transition hover:bg-blue-700"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddCustomer;
