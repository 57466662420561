import React, { useState, useEffect } from "react";
import StarRatings from "react-star-ratings";
import { taskArray } from "../../data/TaskArray"; // Make sure this points to the correct path
import { toast } from "react-toastify";
import axios from "axios";
import PlansPopup from "../../components/PlansPopup ";

const ITEMS_PER_PAGE = 5;

function App({ onChange }) {
  const [agencies, setAgencies] = useState([]); // To store task data
  const [selectedAgency, setSelectedAgency] = useState(null); // Track selected agency for review
  const [showReviewForm, setShowReviewForm] = useState(false); // Toggle review form visibility
  const [reviews, setReviews] = useState([]); // To store reviews
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  // Set agencies data from taskArray on component mount
  useEffect(() => {
    setAgencies(taskArray); // Use the static array of tasks
  }, []);

  // Handle "Complete Task" button click
  const handleCompleteTask = (agency) => {
    setSelectedAgency(agency); // Set selected agency for review
    setShowReviewForm(true); // Show review form
  };

  // Handle review form submission
  const handleSubmitReview = (reviewData) => {
    setReviews([...reviews, reviewData]); // Add new review to reviews array
    setAgencies(
      agencies.filter((agency) => agency.id !== reviewData.agency.id)
    ); // Remove agency from tasks list
    setShowReviewForm(false); // Hide review form
    setSelectedAgency(null); // Clear selected agency
    onChange(reviewData);
  };

  // Pagination logic
  const totalPages = Math.ceil(agencies.length / ITEMS_PER_PAGE);
  const currentAgencies = agencies.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-4 text-center text-blue-600">
        Agency Tasks
      </h1>

      {/* Display Agencies as Tasks */}
      <div className="space-y-4">
        <PlansPopup isOpen={isPopupOpen} setIsOpen={setIsPopupOpen} />
        {currentAgencies.length > 0 ? (
          currentAgencies.map((agency) => (
            <div
              style={{
                backgroundImage: `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREkbP7rIiebR7MWQYeGE9CSyWunyxxVnZUD2cV8W27qJNNfj4oOzD1oZ38L0mRviS1bx0&usqp=CAU')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              key={agency.id}
              className="p-6 bg-white border border-gray-300 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              <h3 className="text-xl  font-semibold text-blue-700">
                {agency.name}{" "}
                <a
                  className="border sm:inline block border-green-500  rounded bg-green-500/40 px-2"
                  href=""
                >
                  {agency.website}
                </a>
                <a
                  className="border my-2 sm:my-0 ml-0 sm:ml-4   sm:inline block border-green-500  rounded bg-green-500/40 px-2"
                  href=""
                >
                  {agency.email}
                </a>
                <a
                  className="border ml-0 sm:ml-4  sm:inline block border-green-500  rounded bg-green-500/40 px-2"
                  href=""
                >
                  {agency.mobileNumber}
                </a>
              </h3>
              <p className="text-gray-600">
                {agency.fullAddress}{" "}
                <StarRatings
                  rating={agency.rating}
                  starRatedColor="blue"
                  starEmptyColor="gray"
                  numberOfStars={5}
                  starDimension="20px"
                  starSpacing="2px"
                />{" "}
              </p>
              <p className="text-gray-600">
                CreatedAt: {agency.time},{agency.date}
              </p>
              <p className="font-bold text-green-600">{agency.tags}</p>
              <button
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200"
                onClick={() => handleCompleteTask(agency)}
              >
                Complete Task
              </button>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">No tasks available.</p>
        )}
      </div>

      {/* Pagination Controls */}
      <div className="mt-8 flex justify-between items-center">
        <button
          className={`px-4 py-2 bg-gray-300 rounded ${
            currentPage === 1
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-gray-400"
          }`}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="text-lg font-semibold">{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          className={`px-4 py-2 bg-gray-300 rounded ${
            currentPage === totalPages
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-gray-400"
          }`}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      {/* Review Form Modal */}
      {showReviewForm && (
        <ReviewForm
          isOpen={isPopupOpen}
          setIsOpen={setIsPopupOpen}
          agency={selectedAgency}
          onSubmit={handleSubmitReview}
          onClose={() => setShowReviewForm(false)}
        />
      )}

      {/* Display Submitted Reviews */}
      {/* <div className="mt-12">
        <h2 className="text-3xl font-bold mb-4">Submitted Reviews</h2>
        {reviews.length > 0 ? (
          reviews.map((review, index) => (
            <div
              key={index}
              className="p-4 mb-4 bg-white border border-gray-300 rounded-lg shadow-md"
            >
              <h3 className="text-lg font-semibold">
                Agency: {review.agency.name}
              </h3>
              <p className="text-gray-600">Review: {review.review}</p>
              <p className="text-gray-600">
                Rating:{" "}
                <StarRatings
                  rating={review.rating}
                  starRatedColor="blue"
                  starEmptyColor="gray"
                  numberOfStars={5}
                  starDimension="20px"
                  starSpacing="2px"
                />{" "}
                / 5
              </p>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No reviews submitted yet.</p>
        )}
      </div> */}
    </div>
  );
}

// Review Form Component
function ReviewForm({ isOpen, setIsOpen, agency, onSubmit, onClose }) {
  const [rating, setRating] = useState(0);
  const [selectedReview, setSelectedReview] = useState(""); // Changed from review to selectedReview

  // Sample array of reviews (you can replace this with your actual list of 50 reviews)
  const reviewOptions = [
    "Excellent service!",
    "Very satisfied with the experience.",
    "Good quality, will use again.",
    "Average experience.",
    "Not what I expected.",
    "Poor customer service.",
    // Add more reviews as needed...
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the rating
    if (rating < 1 || rating > 5) {
      toast.error("Please select a rating between 1 and 5.");
      return;
    }

    try {
      // Send the review data to the server
      const res = await axios.post("/api/sendRev", {
        userToken: localStorage.getItem("userToken"),
        review: selectedReview, // Include the selected review
        rating,
      });

      // Check if the response is successful
      if (res.status === 200) {
        toast.success(res?.data?.message);

        // Submit the review data to the parent component
        onSubmit({ rating, review: selectedReview, agency });
      } else {
         
        toast.error(res?.data?.message || "Review not submitted successfully");
      }
    } catch (error) {
      console.error(error);
      toast.error(
        error?.response?.data?.message ||
          "An error occurred while submitting the review."
      );
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-96">
        <h2 className="text-2xl font-bold mb-4">Review {agency.name}</h2>
        <form onSubmit={handleSubmit}>
          <label className="block text-gray-700">Rating:</label>
          <StarRatings
            rating={rating}
            starRatedColor="blue"
            starEmptyColor="gray"
            numberOfStars={5}
            starDimension="30px"
            starSpacing="5px"
            changeRating={setRating}
            isSelectable
          />
          <label className="block text-gray-700 mt-4">Select a Review:</label>
          <select
            value={selectedReview}
            onChange={(e) => setSelectedReview(e.target.value)}
            className="w-full p-2 mt-2 border border-gray-300 rounded"
            required
          >
            <option value="" disabled>
              Select a review...
            </option>
            {reviewOptions.map((review, index) => (
              <option key={index} value={review}>
                {review}
              </option>
            ))}
          </select>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded mr-2 hover:bg-gray-400"
              onClick={onClose}
            >
              Close
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200"
            >
              Submit Review
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default App;
