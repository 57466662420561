import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container mx-auto text-center">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1 className="animated-text text-5xl font-serif font-bold">
                  {props.data ? props.data.title : "Loading"}
                </h1>
                <p className="text-lg mt-4">{props.data ? props.data.paragraph : "Loading"}</p>
                <a id="featurest" className="btn btn-lg page-scroll">
                  <img
                    onClick={() => props.setIsOpen(!props.isOpen)}
                    className="gif-img"
                    src="https://dms.iitd.ac.in/wp-content/uploads/2017/08/apply-now.gif"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
